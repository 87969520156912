h1, 
.title {
  font-size: 32px;
  line-height: 1.13;
  margin: 0;
}

a {
  color:#febb05;
  text-shadow: 0 0 0 rgba(56,56,56,0); 
}

a:hover {
  text-shadow: 0 0 12px rgba(56,56,56,1); 
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type=submit] {
  font-family: 'Oaxaca';
  font-size: 10px;
  padding: 8px 12px 8px;
  border-radius: 2px;
  border: unset;
  background-color: #383838;
  color: #fff;
}

.button {
  font-family: 'Oaxaca';
}

.App {
  text-align: center;
  color: #febb05;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
}

.landing-page {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 24px;
}

.site-header {
  font-size: 12px;
  flex: 0 1 auto;
}

.site-content {
  font-size: 18px;
  line-height: 1;
  flex: 1 1 auto;
}

.site-description {
  font-size: 14px;
  margin-top: 32px;
}

.site-footer {
  display: flex;
  font-size: 10px;
  flex: 0 1 auto;
  flex-wrap: wrap;
  justify-content: space-between ;
  align-items: center;
  color: #704f24;
  margin: 24px 0 56px;
}

.site-footer > a {
  color: #704f24;
}

.streaming-links {
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-right: 48px;
}

.streaming-icon {
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
}

.divider {
  font-size: 32px;
  margin: 0;
}

.revue-email-form {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.revue-form-field {
  font-family: 'Oaxaca';
  font-size: 10px;
  width: 148px;
  border: 0;
  padding: 8px 12px 8px;
  margin-top: 32px;
}

.revue-form-footer {
  font-size: 10px;
}

.photoCredit {
  font-size: 10px;
  color: rgba(255, 255, 255, .6);
  display: none;
}

@media only screen and (min-width: 375px) {
  h1, 
  .title {
    font-size: 42px;
    line-height: 1.13;
    margin: 0;
  }

  .divider {
    font-size: 42px;
  }

  .site-footer {
    margin: 48px 0 96px;
  }
  
}

@media only screen and (min-width: 768px) {
  h1,
  .title {
    font-size: 48px;
  }

  .site-header {
    font-size: 24px;
  }

  .site-description {
    margin-top: 64px;
    font-size: 24px;
    padding: 0 212px;
  }

  .site-footer {
    font-size: 12px;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    /* margin-top: unset; */
  }

  .site-footer > * {
    margin-bottom: unset;
  }

  .divider {
    font-size: 48px;
    /* margin: 0 0 12px; */
  }

  .revue-form-field {
    font-size: 14px;
    width: 200px;
    padding: 16px 12px 12px;
  }

  .photoCredit {
    display: unset;
  }
}

@media only screen and (min-width: 1080px) {

  .site-content {
    font-size: 24px;
  }

  .site-description {
    padding: 0 320px;
  }

}

@media only screen and (min-width: 1300px) {

  .site-description {
    padding: 0 448px;
  }

}

@media only screen and (min-width: 1400px) {

  .site-description {
    padding: 0 548px;
  }

}

@media only screen and (min-width: 1650px) {

  .site-description {
    padding: 0 648px;
  }

}

@media only screen and (min-width: 1900px) {

  .site-description {
    padding: 0 748px;
  }

}